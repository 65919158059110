import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  TextareaProps,
  ChakraProps,
  Textarea,
} from '@chakra-ui/react'
import IconError from 'components/icons/Error'

type Props = {
  label?: string
  error?: string | Error | false
  containerStyle?: ChakraProps
} & TextareaProps

const Component: React.VFC<Props> = ({ label, error, containerStyle, ...rest }) => (
  <FormControl {...containerStyle} isInvalid={!!error} label={label}>
    {label && (
      <FormLabel fontWeight="bold" fontSize="lg">
        {label}
      </FormLabel>
    )}
    <Textarea
      borderStyle="solid"
      borderWidth="1px"
      borderColor="middlegray"
      borderRadius={4}
      p={1}
      mt={3}
      fontSize="lg"
      lineHeight="150"
      resize="vertical"
      _placeholder={{
        color: 'neutralgray',
      }}
      focusBorderColor="primary.500"
      errorBorderColor="middlegray"
      {...rest}
    />
    {error && (
      <FormErrorMessage>
        <IconError fill="danger.500" mr={2} />
        {error}
      </FormErrorMessage>
    )}
  </FormControl>
)

export default Component
