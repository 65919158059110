/* eslint-disable react/display-name */
import React, { forwardRef } from 'react'
import { Button, ButtonProps as CButtonProps } from '@chakra-ui/react'
import { ButtonStyleProps, getColorStyle, getShapeStyle, getTextColorStyle } from './style'

type Props = ButtonStyleProps & Omit<CButtonProps, 'colorScheme'>

const Component = forwardRef<HTMLButtonElement, Props>(({ shape, colorScheme, ...rest }, ref) => (
  <Button
    tabIndex={0}
    fontSize="lg"
    lineHeight="lg"
    h="52px"
    {...getTextColorStyle(colorScheme)}
    {...getShapeStyle(shape)}
    {...getColorStyle(colorScheme)}
    _hover={{ opacity: 0.8 }}
    _focusVisible={{
      boxShadow: 'secondary',
    }}
    _active={{ bg: 'primary.10', opacity: '0.8' }}
    _disabled={{
      bg: 'middlegray',
      color: 'deepgray',
      cursor: 'not-allowed',
      _hover: {
        opacity: 1,
      },
    }}
    _loading={{
      ...getTextColorStyle(colorScheme),
      ...getColorStyle(colorScheme),
    }}
    ref={ref}
    {...rest}
  />
))

export default Component
export type ButtonProps = Props
