import React from 'react'
import { Image, FormLabel, Box } from '@chakra-ui/react'
import Spinner from '../Spinner'
import ImageInput from './ImageInput'
import { AttachPreviewProps } from './types'

const Component: React.FC<AttachPreviewProps> = ({
  dataUrl,
  types,
  capture,
  disabled,
  children,
  attaching,
  handleSelectFile,
  ...styleProps
}) => (
  <FormLabel p={0} m={0} {...styleProps}>
    {attaching ? (
      <Box w="100%" h="100%" display="flex" alignItems="center" justifyContent="center">
        <Spinner size="lg" />
      </Box>
    ) : (
      <>
        <ImageInput
          handleSelectFile={handleSelectFile}
          types={types}
          capture={capture}
          disabled={disabled}
        />
        {dataUrl == null ? (
          children
        ) : (
          <Image src={dataUrl} alt="送信画像" objectFit="cover" w="100%" h="100%" />
        )}
      </>
    )}
  </FormLabel>
)

export default Component
