import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  ChakraProps,
  forwardRef,
} from '@chakra-ui/react'
import IconError from 'components/icons/Error'
import Input from './Input'

type Props = {
  label?: string
  error?: string | Error | false
  containerStyle?: ChakraProps
} & InputProps

const Component = forwardRef<Props, 'input'>(({ label, error, containerStyle, ...rest }, ref) => (
  <FormControl {...containerStyle} isInvalid={!!error} label={label}>
    {label && (
      <FormLabel fontWeight="normal" mb={0}>
        {label}
      </FormLabel>
    )}
    <Input {...rest} ref={ref} />
    {error && (
      <FormErrorMessage mt={0}>
        <IconError fill="danger.500" mr={2} />
        {error}
      </FormErrorMessage>
    )}
  </FormControl>
))

export default Component
