import { useState } from 'react'
import readImageAsDataURL from './readImageAsDataURL'
import resizeImage from './resizeImage'
import compressJpeg from './compressJpeg'
import { ImageType, AttachedImage, ImageInputProps } from './types'

type Args = {
  types?: ImageInputProps['types']
  handleAttach: (image: AttachedImage) => void
}

const useAttach = ({ handleAttach, types }: Args) => {
  const [image, setImage] = useState<AttachedImage | null>(null)
  const [attaching, setAttaching] = useState(false)

  const handleAttachImage = async (file: File) => {
    setAttaching(true)
    const modifiedFile = await compressJpeg(file, 0.4)
    const readDataUrl = await readImageAsDataURL(modifiedFile).then(resizeImage)

    const attachedImage = {
      name: file.name,
      type: file.type as ImageType,
      dataUrl: readDataUrl,
      size: modifiedFile.size,
      file: modifiedFile,
    }
    setImage(attachedImage)
    handleAttach(attachedImage)
    setAttaching(false)
  }

  const handleSelectFile: ImageInputProps['handleSelectFile'] = e => {
    e.preventDefault()
    if (e.target.files == null) return

    const [file] = Array.from(e.target.files)
    if (
      file != null &&
      ((types == null && file.type.startsWith('image/')) ||
        (types && types.includes(file.type as ImageType)))
    )
      handleAttachImage(file)
  }

  return {
    image,
    attaching,
    handleSelectFile,
  }
}

export default useAttach
