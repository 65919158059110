import { cacheExchange } from '@urql/exchange-graphcache'

const cache = cacheExchange({
  resolvers: {
    Query: {
      question: (_, args) => ({
        __typename: 'Question',
        id: args.id as string,
      }),
    },
  },
})

export default cache
