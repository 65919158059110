import LineInput from './LineInput'
import TextArea from './TextArea'
import Input from './Input'
import Select from './Select'
import CheckBox from './CheckBox'

export default {
  LineInput,
  TextArea,
  Input,
  Select,
  CheckBox,
}
