import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import Head from 'next/head'
import Router from 'next/router'
import Image from 'next/image'
import { Link } from 'components/common'
import { useConfirmation } from 'utils/confirmation'
import { useGetMeQuery } from 'gen/graphql-client-api'
import ArrowLeft from 'components/icons/ArrowLeft'

type ContainerProps = {
  subject: string
  wizard?: boolean
  root?: 'main' | 'ex'
  sticky?: boolean
  isShowBackButton?: boolean
}

type Props = {
  title: string
  path: string
  handleClick: (e: React.MouseEvent) => Promise<void>
  handleBack: () => void
  isShowBackButton: boolean
}

const Component: React.FC<Props> = ({ title, path, handleClick, handleBack, isShowBackButton }) => (
  <>
    <Head>
      <title>{title}</title>
    </Head>
    <Box
      as="header"
      display="grid"
      gridTemplateColumns="1fr 1fr 1fr"
      alignItems="center"
      shadow="sm"
      h="fit-content"
      minH="fit-content"
      py={3}
      px={3}
      bg="white"
      borderBottom="1px"
      borderColor="lightgray"
    >
      <Box>{isShowBackButton && <ArrowLeft onClick={handleBack} />}</Box>
      <Heading as="h1" mx="auto">
        <Link href={path} onClick={handleClick}>
          <Image src="/logo.svg" alt="スマルナロゴ" width={119} height={25} priority />
        </Link>
      </Heading>
    </Box>
  </>
)

const Container: React.FC<ContainerProps> = props => {
  const { wizard, subject, isShowBackButton = true } = props

  const { confirm } = useConfirmation()
  const [{ data }] = useGetMeQuery()

  const rootPath = { auth: '/', unAuth: '/signup' }
  const path = data?.me ? rootPath.auth : rootPath.unAuth

  const title = `${subject} - スマルナ${process.env.NEXT_PUBLIC_APP_ENV ?? ''}`

  const handleClick = async (e: React.MouseEvent) => {
    e.preventDefault()
    if (wizard) {
      if (!(await confirm({ title: `${subject}を中断します。よろしいですか？` }))) {
        return
      }
    }
    Router.push(path)
  }

  return (
    <Component
      handleClick={handleClick}
      handleBack={Router.back}
      title={title}
      path={path}
      isShowBackButton={isShowBackButton}
    />
  )
}

export default Container
