import React from 'react'
import { Provider as UrqlProvider } from 'urql'
import createClient from './client'

type ClientState = {
  resetClient: () => void
}

type Props = {
  origin: string
}

const Context = React.createContext<ClientState>({} as ClientState)

export const Provider: React.FC<Props> = ({ origin, children }) => {
  const [client, setClient] = React.useState(createClient(origin))

  return (
    <Context.Provider
      value={{
        resetClient: () => setClient(createClient(origin)),
      }}
    >
      <UrqlProvider value={client}>{children}</UrqlProvider>
    </Context.Provider>
  )
}

export const useClient = () => React.useContext(Context)
