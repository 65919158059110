import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="24px" height="24px" viewBox="0 0 24 24" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5558 18.3949L10.6636 12.4924L16.5558 6.58993C17.1481 5.99664 17.1481 5.03825 16.5558 4.44497C15.9636 3.85168 15.0068 3.85168 14.4146 4.44497L7.44419 11.4275C6.85194 12.0208 6.85194 12.9792 7.44419 13.5725L14.4146 20.555C15.0068 21.1483 15.9636 21.1483 16.5558 20.555C17.1329 19.9617 17.1481 18.9881 16.5558 18.3949Z"
      fill="#5C5C5C"
    />
  </Icon>
)

export default Component
