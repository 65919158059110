import React, { HTMLProps } from 'react'

import { Box, Stack, useCheckbox, InputProps, CheckboxProps } from '@chakra-ui/react'
import IconTodo from 'components/icons/TodoSquare'
import IconCheck from 'components/icons/DoneSquare'

const Component: React.FC<CheckboxProps> = ({ children, ...props }) => {
  const { getInputProps, getCheckboxProps } = useCheckbox(props)

  const input: Omit<HTMLProps<HTMLInputElement>, never> & InputProps = getInputProps()
  const checkbox = getCheckboxProps()

  return (
    <Box as="label" h={8}>
      <input {...input} />
      <Stack spacing={2} direction="row">
        <Box {...checkbox} lineHeight="14px" alignSelf="flex-start">
          {input.checked ? <IconCheck /> : <IconTodo />}
        </Box>
        <Box>{children}</Box>
      </Stack>
    </Box>
  )
}

export default Component
