import { ButtonProps } from '@chakra-ui/react'

export type ButtonStyleProps = {
  shape?: 'circle' | 'rounded'
  colorScheme?: 'primary' | 'lightgray'
}

export const getShapeStyle = (type: ButtonStyleProps['shape']): ButtonProps => {
  switch (type) {
    case 'rounded':
      return {
        borderRadius: 'md',
      }
    case 'circle':
    default:
      return {
        borderRadius: 'full',
      }
  }
}

export const getColorStyle = (type: ButtonStyleProps['colorScheme']): ButtonProps => {
  switch (type) {
    case 'primary':
      return {
        bg: 'primary.500',
      }
    case 'lightgray':
      return {
        bg: 'lightgray',
      }
    default:
      return {
        bg: 'transparent',
      }
  }
}

export const getTextColorStyle = (type: ButtonStyleProps['colorScheme']): ButtonProps => {
  switch (type) {
    case 'primary':
      return {
        color: 'white',
      }
    case 'lightgray':
      return {
        color: 'deepgray',
      }
    default:
      return {
        color: 'primary.500',
      }
  }
}
