import Compressor from 'compressorjs'

import { ImageType } from './types'

const compressJpeg = (file: File, quality: number): Promise<File> => {
  const blobToFile = (blob: Blob): File => {
    const b: any = blob
    b.lastModifiedDate = new Date()
    b.name = file.name

    return blob as File
  }

  return new Promise(resolve => {
    if (file.type !== ImageType.JPEG) {
      resolve(file)
    } else {
      // eslint-disable-next-line no-new
      new Compressor(file, {
        quality,
        success: result => resolve(blobToFile(result)),
        error: () => resolve(file),
      })
    }
  })
}

export default compressJpeg
