import React from 'react'
import { Box } from '@chakra-ui/react'
import { Spinner } from 'components/common'

const PageSpinner: React.FC = () => (
  <Box h="100vh" display="flex" justifyContent="center" alignItems="center">
    <Spinner height={20} width={20} thickness="4px" />
  </Box>
)

export default PageSpinner
