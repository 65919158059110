import React, { forwardRef } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'

// eslint-disable-next-line react/display-name
const Component = forwardRef<HTMLDivElement, BoxProps>(({ children, ...rest }, ref) => (
  <Box
    flexDirection="column"
    w="full"
    maxWidth="768px"
    margin="0 auto"
    h="full"
    overflowY="scroll"
    {...rest}
    ref={ref}
  >
    {children}
  </Box>
))

export default Component
