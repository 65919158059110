import React from 'react'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import { decodeJwt } from 'utils/jwt'
import { getToken } from 'utils/auth'

if (
  process.env.NEXT_PUBLIC_BUGSNAG_API_KEY != null &&
  process.env.NEXT_PUBLIC_APP_ENV === 'production'
) {
  Bugsnag.start({
    apiKey: process.env.NEXT_PUBLIC_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginReact(React)],
    onError: event => {
      const token = getToken()
      if (token) {
        const { claims } = decodeJwt(token)
        event.setUser(claims.id.toString(), claims.email)
        event.addMetadata('role', { role: claims.role })
      }
    },
  })
}

export default Bugsnag
