import { NextPageContext } from 'next'
import absoluteUrl from 'next-absolute-url'

const getOrigin = (ctx: NextPageContext) => {
  const { req } = ctx

  if (req) {
    const localhostAddress = req.headers.host?.includes('localhost') ? req.headers.host : undefined
    const { origin } = absoluteUrl(req, localhostAddress)

    return origin
  }

  return document.location.origin
}

export default getOrigin
