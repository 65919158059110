import { useCallback, useState } from 'react'
import { InputProps } from '@chakra-ui/react'

type Args = {
  initialType?: InputProps['type']
}

export const usePasswordVisibility = ({ initialType }: Args) => {
  const isPassword = initialType === 'password'
  const [type, setType] = useState(initialType)
  const handleToggle = useCallback(() => {
    if (isPassword) {
      setType(v => (v === 'password' ? 'text' : 'password'))
    }
  }, [isPassword])

  return {
    isPassword,
    isVisible: type !== 'password',
    type,
    handleToggle,
  }
}
