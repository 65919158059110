import { useEffect } from 'react'
import Router from 'next/router'
import { useClient as useAxios } from 'config/axios'
import { isTokenExpired, refreshToken, getToken } from './token'

type Scope = 'private' | 'guest'
type Root = 'main' | 'ex'

const path = {
  main: { auth: '/mypage', unAuth: '/' },
  ex: { auth: '/ex', unAuth: '/ex/social_login' },
} as const

const useAuthGuard = (scope: Scope, root: Root = 'main') => {
  const axios = useAxios()
  const redirectPath = path[root]

  useEffect(() => {
    const isSignedIn = async () => {
      try {
        if (getToken() == null || isTokenExpired()) {
          await refreshToken(axios)
        }

        return true
      } catch (e) {
        // refresh failed
        console.error(e)

        return false
      }
    }
    const guard = async () => {
      const signedIn = await isSignedIn()
      if (signedIn && scope === 'guest') {
        Router.replace(redirectPath.auth)
      } else if (!signedIn && scope === 'private') {
        Router.replace(redirectPath.unAuth)
      }
    }
    guard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}

export default useAuthGuard
