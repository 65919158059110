import React from 'react'
import { Box, Heading } from '@chakra-ui/react'
import { CheckIcon } from '@chakra-ui/icons'
import Link from 'next/link'
import { Button } from 'components/common'

type Props = {
  heading: string
  href: string
  buttonText: string
}

const Component: React.FC<Props> = props => {
  const { heading, href, buttonText } = props

  return (
    <Box bg="white" h="full">
      <Box px={12}>
        <Heading fontSize="lg" fontWeight="bold" pt={20}>
          {heading}
        </Heading>
      </Box>
      <Box display="flex" justifyContent="center" alignItems="center" my={24}>
        <CheckIcon fontSize="6xl" color="primary.500" />
      </Box>
      <Box mb={12} px={12}>
        <Link href={href}>
          <Button colorScheme="primary" width="full">
            {buttonText}
          </Button>
        </Link>
      </Box>
    </Box>
  )
}

export default Component
