import React from 'react'
import { Box, Button, ButtonProps, Grid } from '@chakra-ui/react'
import ArrowRight from 'components/icons/ArrowRight'

type Props = {
  title?: React.ReactNode
  subtitle?: React.ReactNode
  rightTitle?: React.ReactNode
  titlePrimary?: boolean
} & Omit<ButtonProps, 'title'>

const Component: React.FC<Props> = ({
  children,
  title,
  subtitle,
  rightTitle,
  titlePrimary,
  onClick,
  ...rest
}) => {
  const actualTitle = title || children
  const gridTemplateAreas = `
    "title    ${rightTitle ? 'right' : ''} ${onClick ? 'chevron' : ''}"
    "subtitle ${rightTitle ? 'right' : ''} ${onClick ? 'chevron' : ''}"
  `

  return (
    <Button
      as={onClick ? 'button' : 'div'}
      role={onClick ? 'menuitem' : undefined}
      tabIndex={onClick ? 0 : undefined}
      display="block"
      bg="white"
      borderRadius="none"
      colorScheme="whiteAlpha"
      color="black"
      fontSize="md"
      fontWeight="normal"
      width="full"
      minWidth={0}
      h="auto"
      px={3}
      py={4}
      onClick={onClick}
      _hover={
        onClick
          ? {
              opacity: 0.6,
            }
          : undefined
      }
      _focusVisible={{
        boxShadow: 'secondary',
      }}
      {...rest}
    >
      <Grid
        gridTemplateColumns={`1fr ${rightTitle ? '1fr' : ''} ${onClick ? '12px' : ''}`}
        gridTemplateAreas={gridTemplateAreas}
        columnGap={3}
        rowGap={subtitle ? 1 : 0}
        alignItems="center"
        h="full"
        w="full"
      >
        <Box
          gridArea="title"
          color={titlePrimary ? 'primary.500' : undefined}
          textAlign="left"
          whiteSpace="nowrap"
        >
          {actualTitle}
        </Box>
        {subtitle && (
          <Box
            gridArea="subtitle"
            textAlign="left"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {subtitle}
          </Box>
        )}
        {rightTitle && (
          <Box
            gridArea="right"
            textAlign="right"
            whiteSpace="nowrap"
            overflow="hidden"
            textOverflow="ellipsis"
          >
            {rightTitle}
          </Box>
        )}
        {onClick && (
          <Box display="flex" alignItems="center" gridArea="chevron">
            <ArrowRight flexShrink={1} w="full" fill="neutralgray" />
          </Box>
        )}
      </Grid>
    </Button>
  )
}

export default Component
