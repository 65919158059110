import React from 'react'
import { Textarea, TextareaProps } from '@chakra-ui/react'

const Component: React.FC<TextareaProps> = props => (
  <Textarea
    border={0}
    borderBottomStyle="solid"
    borderBottomWidth="1px"
    borderBottomColor="middlegray"
    borderRadius={0}
    p={0}
    mt={3}
    resize="vertical"
    fontSize="lg"
    _placeholder={{
      color: 'neutralgray',
    }}
    _focus={{
      border: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'primary.500',
    }}
    _invalid={{
      border: 0,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      borderBottomColor: 'middlegray',
    }}
    {...props}
  />
)

export default Component
