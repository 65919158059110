import { JPEGOrientation, DataURL } from './types'

/**
 * Reset JPEG Exif's orientation
 * @see https://stackoverflow.com/a/40867559
 */
const resetOrientation = (dataURL: DataURL, orientation: JPEGOrientation): Promise<DataURL> =>
  new Promise(resolve => {
    const img = new Image()

    img.onload = () => {
      const { width, height } = img
      const canvas = document.createElement('canvas')
      const ctx = canvas.getContext('2d')

      if (ctx == null) {
        resolve(dataURL)
      } else {
        // set proper canvas dimensions before transform & export
        if (orientation > 4 && orientation < 9) {
          canvas.width = height
          canvas.height = width
        } else {
          canvas.width = width
          canvas.height = height
        }

        // transform context before drawing image
        switch (orientation) {
          case 2:
            ctx.transform(-1, 0, 0, 1, width, 0)
            break
          case 3:
            ctx.transform(-1, 0, 0, -1, width, height)
            break
          case 4:
            ctx.transform(1, 0, 0, -1, 0, height)
            break
          case 5:
            ctx.transform(0, 1, 1, 0, 0, 0)
            break
          case 6:
            ctx.transform(0, 1, -1, 0, height, 0)
            break
          case 7:
            ctx.transform(0, -1, -1, 0, height, width)
            break
          case 8:
            ctx.transform(0, -1, 1, 0, 0, width)
            break
          default:
            break
        }

        // draw image
        ctx.drawImage(img, 0, 0)

        resolve(canvas.toDataURL())
      }
    }

    img.src = dataURL
  })

export default resetOrientation
