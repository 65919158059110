import React from 'react'
import { Alert, AlertTitle, AlertDescription, BoxProps } from '@chakra-ui/react'

type Props = {
  title?: string
} & Omit<BoxProps, 'title'>

const InfoBox: React.FC<Props> = ({ title, children, ...rest }) => (
  <Alert status="info" bg="primary10" p={3} flexDir="column" {...rest}>
    {title && (
      <AlertTitle color="deepgray" fontWeight="bold" mb={3} alignSelf="flex-start">
        {title}
      </AlertTitle>
    )}
    <AlertDescription fontSize="sm" color="deepgray">
      {children}
    </AlertDescription>
  </Alert>
)

export default InfoBox
