import React from 'react'
import NLink, { LinkProps as NLinkProps } from 'next/link'
import { Link as CLink, LinkProps as CLinkProps } from '@chakra-ui/react'

type Props = { isDecoration?: boolean }
type LinkProps = NLinkProps & CLinkProps & Props
type ExternalLinkProps = Omit<CLinkProps, 'isExternal'> & Props

const Link: React.FC<LinkProps> = ({
  href,
  as,
  replace,
  scroll,
  shallow,
  passHref,
  prefetch,
  isDecoration,
  ...rest
}) => {
  const { isExternal, children, ...restForExternal } = rest

  if (isExternal != null) {
    return (
      <ExternalLink href={href} {...restForExternal} isDecoration={isDecoration}>
        {children}
      </ExternalLink>
    )
  }

  const nLinkProps = {
    href,
    as,
    replace,
    scroll,
    shallow,
    passHref,
    prefetch,
  }

  return (
    <NLink {...nLinkProps}>
      <CLink {...rest} _hover={{ textDecoration: isDecoration ? 'underline' : 'none' }}>
        {children}
      </CLink>
    </NLink>
  )
}

const ExternalLink: React.FC<ExternalLinkProps> = ({ children, isDecoration, ...rest }) => (
  <CLink isExternal {...rest} _hover={{ textDecoration: isDecoration ? 'underline' : 'none' }}>
    {children}
  </CLink>
)

export default Link
