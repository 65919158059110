import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="18" height="18" viewBox="0 0 18 18" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.99984 4.83341C9.45817 4.83341 9.83317 5.20841 9.83317 5.66675V9.00008C9.83317 9.45841 9.45817 9.83341 8.99984 9.83341C8.5415 9.83341 8.1665 9.45841 8.1665 9.00008V5.66675C8.1665 5.20841 8.5415 4.83341 8.99984 4.83341ZM8.99151 0.666748C4.3915 0.666748 0.666504 4.40008 0.666504 9.00008C0.666504 13.6001 4.3915 17.3334 8.99151 17.3334C13.5998 17.3334 17.3332 13.6001 17.3332 9.00008C17.3332 4.40008 13.5998 0.666748 8.99151 0.666748ZM8.99984 15.6667C5.3165 15.6667 2.33317 12.6834 2.33317 9.00008C2.33317 5.31675 5.3165 2.33341 8.99984 2.33341C12.6832 2.33341 15.6665 5.31675 15.6665 9.00008C15.6665 12.6834 12.6832 15.6667 8.99984 15.6667ZM9.83317 13.1667H8.1665V11.5001H9.83317V13.1667Z"
    />
  </Icon>
)

export default Component
