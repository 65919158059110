import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  InputProps,
  TextareaProps,
  ChakraProps,
} from '@chakra-ui/react'
import IconError from 'components/icons/Error'
import Input from './Input'
import Textarea from './Textarea'

type Props = {
  label?: string
  error?: string | Error | false
  containerStyle?: ChakraProps
} & (InputProps | ({ multiline: true } & TextareaProps))

const Component: React.VFC<Props> = ({ label, error, containerStyle, ...rest }) => {
  let input
  if ('multiline' in rest) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { multiline, ...props } = rest
    input = <Textarea {...props} />
  } else {
    input = <Input {...rest} />
  }

  return (
    <FormControl {...containerStyle} isInvalid={!!error} label={label}>
      {label && (
        <FormLabel h={10} fontWeight="bold" fontSize="lg">
          {label}
        </FormLabel>
      )}
      {input}
      {error && (
        <FormErrorMessage h={10}>
          <IconError fill="danger.500" mr={2} />
          {error}
        </FormErrorMessage>
      )}
    </FormControl>
  )
}

export default Component
