import gql from 'graphql-tag'
import * as Urql from 'urql'

export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string
  String: string
  Boolean: boolean
  Int: number
  Float: number
}

/** 住所 */
export type Address = {
  __typename?: 'Address'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  postalCode: Scalars['String']
  prefecture: Scalars['String']
  city: Scalars['String']
  town: Scalars['String']
  building?: Maybe<Scalars['String']>
  phone?: Maybe<Phone>
}

/** チャットメッセージ投稿データ */
export type ChatMessageEnvelopeInput = {
  type: ChatMessageEnvelopeType
  body: Scalars['String']
}

export enum ChatMessageEnvelopeType {
  Text = 'text',
  File = 'file',
}

/**
 * 特設相談窓口
 * * 自治体/法人向け相談窓口
 */
export type ExtraConsultation = {
  __typename?: 'ExtraConsultation'
  id: Scalars['ID']
  type: Scalars['String']
  state: ExtraConsultationState
  userId: Scalars['ID']
  responder: ExtraConsultationResponder
  messages: Array<ExtraConsultationMessage>
  lastRespondedAt: Scalars['String']
  lastResponderType: ExtraConsultationLastResponderType
  name: Scalars['String']
  unreadCount: Scalars['Int']
}

export enum ExtraConsultationLastResponderType {
  User = 'user',
  Admin = 'admin',
}

export type ExtraConsultationMessage = {
  __typename?: 'ExtraConsultationMessage'
  id: Scalars['ID']
}

export type ExtraConsultationResponder = {
  __typename?: 'ExtraConsultationResponder'
  role?: Maybe<ExtraConsultationResponderType>
}

export enum ExtraConsultationResponderType {
  Midwife = 'midwife',
  Pharmacist = 'pharmacist',
}

export enum ExtraConsultationState {
  Active = 'active',
  Inactive = 'inactive',
  Suspended = 'suspended',
}

export enum Gender {
  Male = 'male',
  Female = 'female',
}

export type Mutation = {
  __typename?: 'Mutation'
  /** 初回プロフィール登録 */
  registerProfile?: Maybe<User>
  /** プロフィール更新 */
  updateProfile?: Maybe<User>
  /** メールアドレス変更処理開始 */
  requestChangeEmail?: Maybe<Scalars['Boolean']>
  /** 特設相談窓口をアンロックする */
  unlockExtraConsultation?: Maybe<ExtraConsultation>
  /** 特設相談窓口へのメッセージ投稿 */
  postMessageToExtraConsultation?: Maybe<Scalars['Boolean']>
  /** パスワード変更 */
  changePassword?: Maybe<Scalars['Boolean']>
  /** 認証コードによるメールアドレスの有効化 */
  verifyUserEmail?: Maybe<User>
  /** 認証コードのメール再送 */
  issueUserEmailVerificationCode?: Maybe<Scalars['Boolean']>
}

export type MutationRegisterProfileArgs = {
  profile: RegisterProfileInput
  phone: Scalars['String']
}

export type MutationUpdateProfileArgs = {
  profile: UpdateProfileInput
  phone: Scalars['String']
}

export type MutationRequestChangeEmailArgs = {
  email: Scalars['String']
  redirectUrl: Scalars['String']
}

export type MutationUnlockExtraConsultationArgs = {
  code: Scalars['String']
}

export type MutationPostMessageToExtraConsultationArgs = {
  extraConsultationId: Scalars['ID']
  envelope: ChatMessageEnvelopeInput
}

export type MutationChangePasswordArgs = {
  password: Scalars['String']
}

export type MutationVerifyUserEmailArgs = {
  code: Scalars['String']
}

export type MutationIssueUserEmailVerificationCodeArgs = {
  email?: Maybe<Scalars['String']>
}

/** 電話 */
export type Phone = {
  __typename?: 'Phone'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  number: Scalars['String']
}

/** プロフィール */
export type Profile = {
  __typename?: 'Profile'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  familyName: Scalars['String']
  givenName: Scalars['String']
  familyNameKana?: Maybe<Scalars['String']>
  givenNameKana?: Maybe<Scalars['String']>
  birthday: Scalars['String']
  gender: Gender
  createdAt: Scalars['String']
  age: Scalars['Int']
}

export type Query = {
  __typename?: 'Query'
  /** ログイン中アカウントの情報を取得 */
  me?: Maybe<User>
  /** 開設済みの特設相談窓口一覧を取得 */
  extraConsultations: Array<ExtraConsultation>
  /** 特設相談窓口を取得 */
  extraConsultation?: Maybe<ExtraConsultation>
}

export type QueryExtraConsultationArgs = {
  type: Scalars['String']
}

/** プロフィール登録 */
export type RegisterProfileInput = {
  familyName: Scalars['String']
  givenName: Scalars['String']
  familyNameKana: Scalars['String']
  givenNameKana: Scalars['String']
  birthday: Scalars['String']
  gender: Gender
}

export type UpdateProfileInput = {
  familyName: Scalars['String']
  givenName: Scalars['String']
  familyNameKana: Scalars['String']
  givenNameKana: Scalars['String']
  birthday: Scalars['String']
  gender: Gender
}

/** アカウント */
export type User = {
  __typename?: 'User'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  email: Scalars['String']
  firebaseUid: Scalars['String']
  createdAt: Scalars['String']
  phone?: Maybe<Phone>
  addresses: Array<Address>
  profile?: Maybe<Profile>
  roles: Array<UserRole>
  emailVerifiedAt?: Maybe<Scalars['String']>
  hasPassword: Scalars['Boolean']
}

export type UserAuthentication = {
  __typename?: 'UserAuthentication'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  provider: Scalars['String']
  uid?: Maybe<Scalars['String']>
  createdAt: Scalars['String']
  user?: Maybe<User>
}

/** ロール */
export type UserRole = {
  __typename?: 'UserRole'
  id: Scalars['ID']
  databaseId: Scalars['Int']
  name: Scalars['String']
  createdAt: Scalars['String']
}

export type ExtraConsultationFragment = {
  __typename?: 'ExtraConsultation'
  id: string
  type: string
  name: string
}

export type GetExtraConsultationQueryVariables = Exact<{
  type: Scalars['String']
}>

export type GetExtraConsultationQuery = {
  __typename?: 'Query'
  extraConsultation?: Maybe<{
    __typename?: 'ExtraConsultation'
    id: string
    type: string
    name: string
  }>
}

export type PostMessageToExtraConsultationMutationVariables = Exact<{
  id: Scalars['ID']
  envelope: ChatMessageEnvelopeInput
}>

export type PostMessageToExtraConsultationMutation = {
  __typename?: 'Mutation'
  postMessageToExtraConsultation?: Maybe<boolean>
}

export type ExtraConsultationMypageConsultationSectionFragment = {
  __typename?: 'ExtraConsultation'
  id: string
  type: string
  name: string
  unreadCount: number
}

export type MypageConsultationSectionQueryVariables = Exact<{ [key: string]: never }>

export type MypageConsultationSectionQuery = {
  __typename?: 'Query'
  extraConsultations: Array<{
    __typename?: 'ExtraConsultation'
    id: string
    type: string
    name: string
    unreadCount: number
  }>
}

export type RegisterProfileMutationVariables = Exact<{
  profile: RegisterProfileInput
  phone: Scalars['String']
}>

export type RegisterProfileMutation = {
  __typename?: 'Mutation'
  registerProfile?: Maybe<{
    __typename?: 'User'
    id: string
    profile?: Maybe<{ __typename?: 'Profile'; id: string }>
    phone?: Maybe<{ __typename?: 'Phone'; id: string }>
  }>
}

export type RequestChangeEmailMutationVariables = Exact<{
  email: Scalars['String']
  redirectUrl: Scalars['String']
}>

export type RequestChangeEmailMutation = {
  __typename?: 'Mutation'
  requestChangeEmail?: Maybe<boolean>
}

export type ChangePasswordMutationVariables = Exact<{
  password: Scalars['String']
}>

export type ChangePasswordMutation = { __typename?: 'Mutation'; changePassword?: Maybe<boolean> }

export type UnlockExtraConsultationMutationVariables = Exact<{
  code: Scalars['String']
}>

export type UnlockExtraConsultationMutation = {
  __typename?: 'Mutation'
  unlockExtraConsultation?: Maybe<{ __typename?: 'ExtraConsultation'; id: string }>
}

export type UpdateProfileMutationVariables = Exact<{
  profile: UpdateProfileInput
  phone: Scalars['String']
}>

export type UpdateProfileMutation = {
  __typename?: 'Mutation'
  updateProfile?: Maybe<{
    __typename?: 'User'
    id: string
    phone?: Maybe<{ __typename?: 'Phone'; id: string; number: string }>
    profile?: Maybe<{
      __typename?: 'Profile'
      id: string
      familyName: string
      givenName: string
      familyNameKana?: Maybe<string>
      givenNameKana?: Maybe<string>
      birthday: string
      gender: Gender
    }>
  }>
}

export type VerifyEmailMutationVariables = Exact<{
  code: Scalars['String']
}>

export type VerifyEmailMutation = {
  __typename?: 'Mutation'
  verifyUserEmail?: Maybe<{ __typename?: 'User'; id: string; emailVerifiedAt?: Maybe<string> }>
}

export type IssueUserEmailVerificationCodeMutationVariables = Exact<{
  email: Scalars['String']
}>

export type IssueUserEmailVerificationCodeMutation = {
  __typename?: 'Mutation'
  issueUserEmailVerificationCode?: Maybe<boolean>
}

export type GetMeQueryVariables = Exact<{ [key: string]: never }>

export type GetMeQuery = {
  __typename?: 'Query'
  me?: Maybe<{
    __typename?: 'User'
    id: string
    databaseId: number
    email: string
    firebaseUid: string
    emailVerifiedAt?: Maybe<string>
    phone?: Maybe<{ __typename?: 'Phone'; id: string; number: string }>
    profile?: Maybe<{
      __typename?: 'Profile'
      id: string
      familyName: string
      givenName: string
      familyNameKana?: Maybe<string>
      givenNameKana?: Maybe<string>
      birthday: string
      gender: Gender
      createdAt: string
    }>
  }>
}

export const ExtraConsultationFragmentDoc = gql`
  fragment ExtraConsultation on ExtraConsultation {
    id
    type
    name
  }
`
export const ExtraConsultationMypageConsultationSectionFragmentDoc = gql`
  fragment ExtraConsultationMypageConsultationSection on ExtraConsultation {
    id
    type
    name
    unreadCount
  }
`
export const GetExtraConsultationDocument = gql`
  query GetExtraConsultation($type: String!) {
    extraConsultation(type: $type) {
      ...ExtraConsultation
    }
  }
  ${ExtraConsultationFragmentDoc}
`

export const useGetExtraConsultationQuery = (
  options: Omit<Urql.UseQueryArgs<GetExtraConsultationQueryVariables>, 'query'> = {},
) => Urql.useQuery<GetExtraConsultationQuery>({ query: GetExtraConsultationDocument, ...options })
export const PostMessageToExtraConsultationDocument = gql`
  mutation PostMessageToExtraConsultation($id: ID!, $envelope: ChatMessageEnvelopeInput!) {
    postMessageToExtraConsultation(extraConsultationId: $id, envelope: $envelope)
  }
`

export const usePostMessageToExtraConsultationMutation = () =>
  Urql.useMutation<
    PostMessageToExtraConsultationMutation,
    PostMessageToExtraConsultationMutationVariables
  >(PostMessageToExtraConsultationDocument)
export const MypageConsultationSectionDocument = gql`
  query MypageConsultationSection {
    extraConsultations {
      ...ExtraConsultationMypageConsultationSection
    }
  }
  ${ExtraConsultationMypageConsultationSectionFragmentDoc}
`

export const useMypageConsultationSectionQuery = (
  options: Omit<Urql.UseQueryArgs<MypageConsultationSectionQueryVariables>, 'query'> = {},
) =>
  Urql.useQuery<MypageConsultationSectionQuery>({
    query: MypageConsultationSectionDocument,
    ...options,
  })
export const RegisterProfileDocument = gql`
  mutation RegisterProfile($profile: RegisterProfileInput!, $phone: String!) {
    registerProfile(profile: $profile, phone: $phone) {
      id
      profile {
        id
      }
      phone {
        id
      }
    }
  }
`

export const useRegisterProfileMutation = () =>
  Urql.useMutation<RegisterProfileMutation, RegisterProfileMutationVariables>(
    RegisterProfileDocument,
  )
export const RequestChangeEmailDocument = gql`
  mutation RequestChangeEmail($email: String!, $redirectUrl: String!) {
    requestChangeEmail(email: $email, redirectUrl: $redirectUrl)
  }
`

export const useRequestChangeEmailMutation = () =>
  Urql.useMutation<RequestChangeEmailMutation, RequestChangeEmailMutationVariables>(
    RequestChangeEmailDocument,
  )
export const ChangePasswordDocument = gql`
  mutation ChangePassword($password: String!) {
    changePassword(password: $password)
  }
`

export const useChangePasswordMutation = () =>
  Urql.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument)
export const UnlockExtraConsultationDocument = gql`
  mutation UnlockExtraConsultation($code: String!) {
    unlockExtraConsultation(code: $code) {
      id
    }
  }
`

export const useUnlockExtraConsultationMutation = () =>
  Urql.useMutation<UnlockExtraConsultationMutation, UnlockExtraConsultationMutationVariables>(
    UnlockExtraConsultationDocument,
  )
export const UpdateProfileDocument = gql`
  mutation UpdateProfile($profile: UpdateProfileInput!, $phone: String!) {
    updateProfile(profile: $profile, phone: $phone) {
      id
      phone {
        id
        number
      }
      profile {
        id
        familyName
        givenName
        familyNameKana
        givenNameKana
        birthday
        gender
      }
    }
  }
`

export const useUpdateProfileMutation = () =>
  Urql.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument)
export const VerifyEmailDocument = gql`
  mutation VerifyEmail($code: String!) {
    verifyUserEmail(code: $code) {
      id
      emailVerifiedAt
    }
  }
`

export const useVerifyEmailMutation = () =>
  Urql.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument)
export const IssueUserEmailVerificationCodeDocument = gql`
  mutation IssueUserEmailVerificationCode($email: String!) {
    issueUserEmailVerificationCode(email: $email)
  }
`

export const useIssueUserEmailVerificationCodeMutation = () =>
  Urql.useMutation<
    IssueUserEmailVerificationCodeMutation,
    IssueUserEmailVerificationCodeMutationVariables
  >(IssueUserEmailVerificationCodeDocument)
export const GetMeDocument = gql`
  query GetMe {
    me {
      id
      databaseId
      email
      firebaseUid
      phone {
        id
        number
      }
      profile {
        id
        familyName
        givenName
        familyNameKana
        givenNameKana
        birthday
        gender
        createdAt
      }
      emailVerifiedAt
    }
  }
`

export const useGetMeQuery = (
  options: Omit<Urql.UseQueryArgs<GetMeQueryVariables>, 'query'> = {},
) => Urql.useQuery<GetMeQuery>({ query: GetMeDocument, ...options })
