import React, { useMemo } from 'react'
import { AppContext, AppProps } from 'next/app'
import Head from 'next/head'
import { ChakraProvider } from '@chakra-ui/react'
import Bugsnag from 'config/bugsnag'
import { Provider as UrqlProvider } from 'config/urql'
import { createClient as createAxios, Provider as AxiosProvider } from 'config/axios'
import { ConfirmationProvider } from 'utils/confirmation'
import customTheme from 'style/theme'
import 'style/app.css'
import getOrigin from 'utils/getOrigin'

type Props = {
  origin: string
} & AppProps

const DummyErrorBoundary: React.FC = ({ children }) => <>{children}</>
const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React) || DummyErrorBoundary

const App = (props: Props) => {
  const { Component, pageProps, origin } = props
  const axios = useMemo(() => createAxios(origin), [origin])

  return (
    <ErrorBoundary>
      <UrqlProvider origin={origin}>
        <AxiosProvider value={axios}>
          <ChakraProvider theme={customTheme}>
            <ConfirmationProvider>
              <Head>
                <title>スマルナ</title>
                <meta
                  name="viewport"
                  content="width=device-width, initial-scale=1, shrink-to-fit=no"
                />
              </Head>
              <Component {...pageProps} />
            </ConfirmationProvider>
          </ChakraProvider>
        </AxiosProvider>
      </UrqlProvider>
    </ErrorBoundary>
  )
}

App.getInitialProps = async (appContext: AppContext) => {
  const origin = getOrigin(appContext.ctx)

  return { origin }
}

export default App
