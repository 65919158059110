/* eslint-disable no-bitwise */

import { JPEGOrientation } from './types'

/**
 * Get JPEG Exif's orientation number
 * @see https://stackoverflow.com/a/32490603
 */
const getOrientation = (file: File): Promise<JPEGOrientation> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = () => {
      if (reader.result == null) {
        reject()
      } else {
        // eslint-disable-next-line no-use-before-define
        resolve(extractOrientation(reader.result as ArrayBuffer))
      }
    }
    reader.readAsArrayBuffer(file)
  })

const extractOrientation = (data: ArrayBuffer): JPEGOrientation => {
  const view = new DataView(data)
  if (view.getUint16(0, false) !== 0xffd8) {
    return -2
  }
  const length = view.byteLength

  let offset = 2
  while (offset < length) {
    if (view.getUint16(offset + 2, false) <= 8) return -1
    const marker = view.getUint16(offset, false)
    offset += 2
    if (marker === 0xffe1) {
      offset += 2
      if (view.getUint32(offset, false) !== 0x45786966) {
        return -1
      }

      const little = view.getUint16((offset += 6), false) === 0x4949
      offset += view.getUint32(offset + 4, little)
      const tags = view.getUint16(offset, little)
      offset += 2
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < tags; i++) {
        if (view.getUint16(offset + i * 12, little) === 0x0112) {
          return view.getUint16(offset + i * 12 + 8, little)
        }
      }
    } else if ((marker & 0xff00) !== 0xff00) {
      break
    } else {
      offset += view.getUint16(offset, false)
    }
  }

  return -1
}

export default getOrientation
