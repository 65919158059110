import { DataURL } from './types'

const DEFAULT_MAX_SIZE = 1200
const resizeImage = (dataURL: DataURL, maxSize: number = DEFAULT_MAX_SIZE): Promise<DataURL> =>
  new Promise(resolve => {
    const img = new Image()

    img.onload = () => {
      const { width, height } = img
      if (width <= maxSize && height <= maxSize) resolve(dataURL)
      else {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        if (ctx == null) {
          resolve(dataURL)
        } else {
          if (width > height) {
            const ratio = height / width
            canvas.width = maxSize
            canvas.height = maxSize * ratio
          } else {
            const ratio = width / height
            canvas.width = maxSize * ratio
            canvas.height = maxSize
          }

          // draw image
          ctx.drawImage(img, 0, 0, width, height, 0, 0, canvas.width, canvas.height)

          resolve(canvas.toDataURL())
        }
      }
    }

    img.src = dataURL
  })

export default resizeImage
