import React from 'react'
import { Select, SelectProps } from '@chakra-ui/react'

type Props = SelectProps

const Component: React.FC<Props> = props => (
  <Select
    variant="flushed"
    placeholder="選択してください"
    borderColor="middlegray"
    fontSize="lg"
    _focus={{
      borderColor: 'primary.500',
      boxShadow: 'none',
    }}
    _invalid={{
      borderColor: 'middlegray',
      boxShadow: 'none',
    }}
    {...props}
  />
)

export default Component
