import React from 'react'
import { Box, Button, ButtonProps, Stack, VisuallyHidden } from '@chakra-ui/react'
import ArrowRight from 'components/icons/ArrowRight'

type Props = {
  title?: React.ReactNode
  rightTitle?: React.ReactNode
  rightElement?: React.ReactNode
  titlePrimary?: boolean
  actionTitle?: string
} & Omit<ButtonProps, 'title'>

const Component: React.FC<Props> = ({
  children,
  title,
  rightTitle,
  rightElement,
  titlePrimary,
  actionTitle,
  onClick,
}) => {
  const actualTitle = title || children

  return (
    <Button
      as={onClick ? 'button' : 'div'}
      role={onClick ? 'menuitem' : undefined}
      tabIndex={onClick ? 0 : undefined}
      display="block"
      bg="white"
      borderRadius="none"
      onClick={onClick}
      colorScheme="whiteAlpha"
      color="black"
      fontSize="md"
      fontWeight="normal"
      minWidth={0}
      h={12}
      _hover={
        onClick
          ? {
              opacity: 0.6,
            }
          : undefined
      }
      _focusVisible={{
        boxShadow: 'secondary',
      }}
    >
      <Stack direction="row" spacing={3} alignItems="center" h="full">
        <Box
          color={titlePrimary ? 'primary.500' : undefined}
          flexGrow={1}
          justifyContent="flex-start"
          justifySelf="flex-start"
          textAlign="left"
          whiteSpace="nowrap"
        >
          {actualTitle}
        </Box>
        <Box
          flexGrow={1}
          justifyContent="flex-end"
          justifySelf="flex-end"
          textAlign="right"
          whiteSpace="nowrap"
          overflow="hidden"
          textOverflow="ellipsis"
        >
          {rightTitle}
        </Box>
        {onClick && (
          <>
            {actionTitle && <VisuallyHidden>{actionTitle}</VisuallyHidden>}
            <Box flexShrink={0}>
              {rightElement ?? <ArrowRight flexShrink={1} w="full" fill="neutralgray" />}
            </Box>
          </>
        )}
      </Stack>
    </Button>
  )
}

export default Component
