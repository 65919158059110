import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="21px" height="20px" viewBox="0 0 21 20" {...props}>
    <rect x="3.98242" y="3" width="14" height="14" rx="1" fill="#F5AFA0" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.8155 6.17561C16.0381 6.40976 16.0381 6.78199 15.8155 7.01614L9.34476 13.8244C9.12222 14.0585 8.76844 14.0585 8.5459 13.8244L6.14933 11.3028C5.92679 11.0687 5.92679 10.6964 6.14933 10.4623C6.37187 10.2281 6.72565 10.2281 6.94819 10.4623L8.94533 12.5636L15.0167 6.17561C15.2392 5.94146 15.593 5.94146 15.8155 6.17561Z"
      fill="white"
    />
  </Icon>
)

export default Component
