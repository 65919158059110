import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="12px" height="12px" viewBox="0 0 12 12" {...props}>
    <path d="M7.63644 5.99321L2.92451 10.7052C2.78659 10.8482 2.71031 11.0397 2.71207 11.2383C2.71384 11.437 2.79351 11.627 2.93395 11.7675C3.07439 11.9081 3.26438 11.9878 3.46304 11.9897C3.6617 11.9916 3.85316 11.9154 3.99623 11.7776L9.24468 6.52937C9.3151 6.45897 9.37096 6.37538 9.40907 6.28338C9.44718 6.19139 9.4668 6.09279 9.4668 5.99321C9.4668 5.89363 9.44718 5.79503 9.40907 5.70304C9.37096 5.61104 9.3151 5.52745 9.24468 5.45705C9.23436 5.44673 9.2232 5.43749 9.2124 5.42789C9.2016 5.41829 9.1932 5.40545 9.18252 5.39477L4.00931 0.22169C3.86662 0.0820028 3.67458 0.00425118 3.47489 0.00531997C3.27521 0.00638876 3.08401 0.0861917 2.94282 0.227399C2.80163 0.368605 2.72185 0.559813 2.7208 0.759496C2.71976 0.959178 2.79753 1.15121 2.93723 1.29389L7.63644 5.99321Z" />
  </Icon>
)

export default Component
