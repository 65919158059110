import { FormLabelProps } from '@chakra-ui/react'

export enum ImageType {
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  GIF = 'image/gif',
}

export type AttachedImage = {
  name: string
  type: ImageType
  dataUrl: DataURL
  size: number
  file: File
}

export type JPEGOrientation = number
export type DataURL = string

export type ImageInputProps = {
  types?: Array<ImageType>
  capture?: 'user' | 'environment'
  disabled?: boolean
  handleSelectFile: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export type AttachPreviewProps = FormLabelProps &
  ImageInputProps & {
    dataUrl?: DataURL
    attaching: boolean
  }
