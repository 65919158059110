import { createClient } from 'urql'
import { dedupExchange, fetchExchange } from '@urql/core'
import { devtoolsExchange } from '@urql/devtools'
import cacheExchange from './cache'
import { configureFetch } from './fetch'

const exchanges =
  process.env.NODE_ENV === 'development'
    ? [devtoolsExchange, dedupExchange, cacheExchange, fetchExchange]
    : [dedupExchange, cacheExchange, fetchExchange]

const client = (origin: string) => {
  const fetch = configureFetch()
  const config = {
    url: `${origin}/api/graphql`,
    exchanges,
    fetch,
  }

  return createClient(config)
}

export default client
