import React from 'react'
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  SelectProps,
  ChakraProps,
  FormLabelProps,
} from '@chakra-ui/react'
import IconError from 'components/icons/Error'
import Select from './Select'

type Props = {
  label?: string
  error?: string | Error | false
  containerStyle?: ChakraProps
  labelStyle?: FormLabelProps
} & SelectProps

const Component: React.FC<Props> = ({ label, error, containerStyle, labelStyle, ...rest }) => (
  <FormControl {...containerStyle} isInvalid={!!error} label={label}>
    {label && (
      <FormLabel fontWeight="bold" h={10} fontSize="lg" {...labelStyle}>
        {label}
      </FormLabel>
    )}
    <Select {...rest} />
    {error && (
      <FormErrorMessage h={10}>
        <IconError fill="danger.500" mr={2} />
        {error}
      </FormErrorMessage>
    )}
  </FormControl>
)

export default Component
