import React from 'react'
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  forwardRef,
} from '@chakra-ui/react'
import IconEye from 'components/icons/Eye'
import IconEyeClosed from 'components/icons/EyeClosed'
import { usePasswordVisibility } from '../usePasswordVisibility'

type Props = InputProps & {
  passwordVisibility: ReturnType<typeof usePasswordVisibility>
}

const Component = forwardRef<Props, 'input'>(({ passwordVisibility, ...rest }, ref) => (
  <InputGroup>
    <Input
      type={passwordVisibility.type}
      border={0}
      borderRadius={0}
      p={0}
      fontSize="lg"
      _placeholder={{
        color: 'neutralgray',
      }}
      _focus={{
        border: 0,
      }}
      _invalid={{
        border: 0,
      }}
      ref={ref}
      {...rest}
    />
    {passwordVisibility.isPassword && (
      <InputRightElement>
        <Button
          tabIndex={-1}
          onClick={passwordVisibility.handleToggle}
          colorScheme="whiteAlpha"
          aria-label={
            passwordVisibility.isVisible ? 'パスワードを非表示にする' : 'パスワードを表示する'
          }
        >
          {passwordVisibility.isVisible ? (
            <IconEye fill="black" />
          ) : (
            <IconEyeClosed fill="neutralgray" />
          )}
        </Button>
      </InputRightElement>
    )}
  </InputGroup>
))

const Container = forwardRef<InputProps, 'input'>((props, ref) => {
  const { type, ...rest } = props

  const passwordVisibility = usePasswordVisibility({ initialType: type })

  return <Component {...rest} ref={ref} passwordVisibility={passwordVisibility} />
})

export default Container
