/* eslint-disable max-len */
import { setToken } from 'utils/auth'
import firebase from 'config/firebase'
import { FIREBASE_CUSTOM_TOKEN } from 'constants/headers'

const configureFetch = () => {
  if (typeof window === 'undefined') return undefined

  const fetch: typeof window.fetch = (url, options) =>
    window.fetch(url, options).then(response => {
      // Firebase-Custom-Token ヘッダがある場合はインメモリJWTを更新する
      const token = response.headers.get(FIREBASE_CUSTOM_TOKEN)
      if (token != null) {
        setToken(token)
        firebase.auth().signInWithCustomToken(token)
      }

      return response
    })

  return fetch
}

export { configureFetch }
