import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="22px" height="20px" viewBox="0 0 22 20" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 4.49969C13.76 4.49969 16 6.73969 16 9.49969C16 10.0097 15.9 10.4997 15.76 10.9597L18.82 14.0197C20.21 12.7897 21.31 11.2497 22 9.48969C20.27 5.10969 16 1.99969 11 1.99969C9.73 1.99969 8.51 2.19969 7.36 2.56969L9.53 4.73969C10 4.59969 10.49 4.49969 11 4.49969ZM1.71 1.15969C1.32 1.54969 1.32 2.17969 1.71 2.56969L3.68 4.53969C2.06 5.82969 0.77 7.52969 0 9.49969C1.73 13.8897 6 16.9997 11 16.9997C12.52 16.9997 13.97 16.6997 15.31 16.1797L18.03 18.8997C18.42 19.2897 19.05 19.2897 19.44 18.8997C19.83 18.5097 19.83 17.8797 19.44 17.4897L3.13 1.15969C2.74 0.769688 2.1 0.769688 1.71 1.15969ZM11 14.4997C8.24 14.4997 6 12.2597 6 9.49969C6 8.72969 6.18 7.99969 6.49 7.35969L8.06 8.92969C8.03 9.10969 8 9.29969 8 9.49969C8 11.1597 9.34 12.4997 11 12.4997C11.2 12.4997 11.38 12.4697 11.57 12.4297L13.14 13.9997C12.49 14.3197 11.77 14.4997 11 14.4997ZM13.97 9.16969C13.82 7.76969 12.72 6.67969 11.33 6.52969L13.97 9.16969Z"
      fill="#BDBDBD"
    />
  </Icon>
)

export default Component
