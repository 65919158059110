import { AxiosInstance } from 'axios'
import decode from 'jwt-decode'

export type AuthTokenPayloadClaims = {
  id: number
  email: string
  role: 'customer'
}

type AuthTokenPayload = {
  exp: number
  claims: AuthTokenPayloadClaims
}

let inMemoryToken: string | null

const getToken = () => inMemoryToken

const setToken = (jwtToken: string) => {
  inMemoryToken = jwtToken
}

const removeToken = () => {
  inMemoryToken = null
}

const refreshToken = (axios: AxiosInstance) => axios.post('refresh_token')

const isTokenExpired = () => {
  if (inMemoryToken == null) throw new Error()
  const { exp } = decode<AuthTokenPayload>(inMemoryToken)
  const now = Date.now()

  return exp * 1000 < now
}

export { getToken, setToken, removeToken, refreshToken, isTokenExpired }
