import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="21px" height="20px" viewBox="0 0 21 20" {...props}>
    <rect fill="transparent" x="4.48242" y="3.5" width="13" height="13" rx="0.5" stroke="#E0E0E0" />
  </Icon>
)

export default Component
