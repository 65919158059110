import { extendTheme } from '@chakra-ui/react'

const primaryColor = 'rgba(243, 161, 144, 1)'
const dangerColor = '#EB5757'

const theme = extendTheme({
  styles: {
    global: {
      html: {
        fontSize: '16px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
      body: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        bg: 'lightgray',
        color: 'black',
        fontSize: '14px',
      },
      '#__next': {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      },
    },
  },
  fontSizes: {
    xs: '10px',
    sm: '12px',
    md: '14px',
    lg: '16px',
    xl: '18px',
    xxl: '20px',
    '2xl': '22px',
    '3xl': '26px',
    '4xl': '34px',
    '5xl': '46px',
    '6xl': '62px',
  },
  lineHeights: {
    /* sm-xxl は同名のフォントサイズに対応 */
    sm: '160%',
    md: '160%',
    lg: '150%',
    xl: '150%',
    xxl: '150%',
    150: '150%',
    160: '160%',
    180: '180%',
  },
  shadows: {
    outline: 'none',
    secondary: '0 0 1px 2px rgba(160, 215, 212, .75), 0 1px 1px rgba(0, 0, 0, .15)',
  },
  colors: {
    black: '#5C5C5C',
    white: '#FFFFFF',
    deepgray: '#757575',
    neutralgray: '#BDBDBD',
    middlegray: '#E0E0E0',
    lightgray: '#F2F2F2',
    primary10: 'rgba(243, 161, 144, 0.1)',
    primary40: 'rgba(243, 161, 144, 0.4)',
    secondary: 'rgba(130, 215, 210, 1)',
    secondary10: 'rgba(130, 215, 210, 0.1)',
    danger80: '#EF7979',
    danger10: '#FDEEEE',
    success: '#6FCF97',
    success80: '#8CD9AC',
    success10: '#F1FAF5',
    warning: '#F2C94C',
    warning80: '#F5D470',
    warning10: '#FEFAED',
    gray: {
      200: '#757575', // deepgray
      300: '#BDBDBD', // neutralgray
      400: '#E0E0E0', // middlegray
      500: 'F2F2F2', // lightgray
    },
    primary: {
      50: primaryColor,
      100: primaryColor,
      200: primaryColor,
      300: primaryColor,
      400: primaryColor,
      500: primaryColor,
      600: primaryColor,
      700: primaryColor,
      800: primaryColor,
      900: primaryColor,
    },
    danger: {
      50: dangerColor,
      100: dangerColor,
      200: dangerColor,
      300: dangerColor,
      400: dangerColor,
      500: dangerColor,
      600: dangerColor,
      700: dangerColor,
      800: dangerColor,
      900: dangerColor,
    },
  },
  spinners: {
    color: 'primary.500',
  },
})

export default theme
