import { useContext, createContext } from 'react'
import axios, { AxiosInstance } from 'axios'
import firebase from 'config/firebase'
import { setToken } from 'utils/auth'
import { FIREBASE_CUSTOM_TOKEN } from '../constants/headers'

const Context = createContext<AxiosInstance>(axios)
const { Provider } = Context

const useClient = () => useContext(Context)

const createClient = (origin: string) => {
  const client = axios.create({
    baseURL: `${origin}/api`,
    withCredentials: true,
  })

  client.interceptors.response.use(async response => {
    const token = response.headers[FIREBASE_CUSTOM_TOKEN]
    if (token != null) {
      setToken(token)
      firebase.auth().signInWithCustomToken(token)
    }

    return response
  })

  return client
}

export { Context, Provider, useClient, createClient }
