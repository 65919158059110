/* eslint-disable max-len */
import getJpegOrientation from './getJpegOrientation'
import resetJpegOrientation from './resetJpegOrientation'
import { ImageType, DataURL } from './types'

const readImageAsDataURL = async (file: File): Promise<DataURL> => {
  // eslint-disable-next-line no-use-before-define
  const dataURL = await readFileAsDataURL(file)
  if (file.type === ImageType.JPEG) {
    const orientation = await getJpegOrientation(file)

    return resetJpegOrientation(dataURL, orientation)
  }

  return dataURL
}

const readFileAsDataURL = (file: File): Promise<DataURL> =>
  new Promise<DataURL>((resolve, reject) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      const dataUrl = reader.result as DataURL
      resolve(dataUrl)
    })
    reader.addEventListener('error', reject)
    reader.readAsDataURL(file)
  })

export default readImageAsDataURL
