import React from 'react'
import { Icon, IconProps } from '@chakra-ui/react'

type Props = IconProps

const Component: React.VFC<Props> = props => (
  <Icon width="28px" height="27px" viewBox="0 0 28 27" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.0089 0.0712891C10.1302 0.0712891 6.97824 3.18584 6.97824 7.06301C6.97824 10.9402 10.1302 14.0547 14.0089 14.0547C17.8942 14.0547 21.0212 10.9337 21.0212 7.06301C21.0212 3.19236 17.8942 0.0712891 14.0089 0.0712891ZM9.97824 7.06301C9.97824 4.86267 11.767 3.07129 14.0089 3.07129C16.2443 3.07129 18.0212 4.85615 18.0212 7.06301C18.0212 9.26986 16.2443 11.0547 14.0089 11.0547C11.767 11.0547 9.97824 9.26335 9.97824 7.06301ZM13.9995 15.989C11.7519 15.989 8.46227 16.5193 5.69664 17.5807C4.3139 18.1114 2.96573 18.8109 1.93768 19.7202C0.9023 20.636 0.0712891 21.8812 0.0712891 23.4488V25.1426C0.0712891 25.9711 0.742862 26.6426 1.57129 26.6426C2.39972 26.6426 3.07129 25.9711 3.07129 25.1426V23.4488C3.07129 23.0327 3.2767 22.541 3.92524 21.9674C4.58111 21.3873 5.56435 20.8448 6.77156 20.3815C9.18585 19.4549 12.1104 18.989 13.9995 18.989C15.8887 18.989 18.8132 19.4549 21.2275 20.3815C22.4347 20.8448 23.418 21.3873 24.0738 21.9674C24.7224 22.541 24.9278 23.0327 24.9278 23.4488V25.1426C24.9278 25.9711 25.5994 26.6426 26.4278 26.6426C27.2562 26.6426 27.9278 25.9711 27.9278 25.1426V23.4488C27.9278 21.8812 27.0968 20.636 26.0614 19.7202C25.0333 18.8109 23.6852 18.1114 22.3024 17.5807C19.5368 16.5193 16.2472 15.989 13.9995 15.989Z"
      fill="#BDBDBD"
    />
  </Icon>
)

export default Component
