import React from 'react'
import { Stack, useRadioGroup, UseRadioGroupProps } from '@chakra-ui/react'
import RadioCard from './RadioCard'

type Props<T> = UseRadioGroupProps & {
  options: T[]
  valueExtractor: (item: T, index?: number) => string
  labelRenderer: (item: T, index?: number) => React.ReactElement
}

type Radio = <T>(props: Props<T>) => React.ReactElement

const Component: Radio = ({ options, valueExtractor, labelRenderer, ...rest }) => {
  const { getRootProps, getRadioProps } = useRadioGroup(rest)
  const group = getRootProps()

  return (
    <Stack {...group}>
      {options.map(item => {
        const key = labelRenderer(item)
        const value = valueExtractor(item)
        const radio = getRadioProps({ value })

        return (
          <RadioCard key={value} {...radio}>
            {key}
          </RadioCard>
        )
      })}
    </Stack>
  )
}

export default Component
