import React from 'react'
import { Input } from '@chakra-ui/react'
import { ImageInputProps } from './types'

const Component: React.FC<ImageInputProps> = ({
  handleSelectFile,
  types = ['image/*'],
  capture,
  disabled,
}) => (
  <Input
    type="file"
    value="" // 同じファイルを選んでも onChange が動くように value を空文字で固定する
    onChange={handleSelectFile}
    accept={types.join(',')}
    capture={capture}
    display="none"
    isDisabled={disabled}
  />
)

export default Component
