import React from 'react'
import { Button, Input, InputGroup, InputRightElement, InputProps } from '@chakra-ui/react'
import IconEye from 'components/icons/Eye'
import IconEyeClosed from 'components/icons/EyeClosed'
import { usePasswordVisibility } from '../usePasswordVisibility'

type Props = InputProps & {
  passwordVisibility: ReturnType<typeof usePasswordVisibility>
}

const Component: React.FC<Props> = ({ passwordVisibility, ...rest }) => (
  <InputGroup mt={3}>
    <Input
      type={passwordVisibility.type}
      border={0}
      borderBottomStyle="solid"
      borderBottomWidth="1px"
      borderBottomColor="middlegray"
      borderRadius={0}
      fontSize="lg"
      p={0}
      _placeholder={{
        color: 'neutralgray',
      }}
      _focus={{
        border: 0,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'primary.500',
      }}
      _invalid={{
        border: 0,
        borderBottomStyle: 'solid',
        borderBottomWidth: '1px',
        borderBottomColor: 'middlegray',
      }}
      {...rest}
    />
    {passwordVisibility.isPassword && (
      <InputRightElement>
        <Button
          tabIndex={-1}
          onClick={passwordVisibility.handleToggle}
          colorScheme="whiteAlpha"
          aria-label={
            passwordVisibility.isVisible ? 'パスワードを非表示にする' : 'パスワードを表示する'
          }
        >
          {passwordVisibility.isVisible ? (
            <IconEye fill="black" />
          ) : (
            <IconEyeClosed fill="neutralgray" />
          )}
        </Button>
      </InputRightElement>
    )}
  </InputGroup>
)

const Container: React.FC<InputProps> = props => {
  const { type, ...rest } = props

  const passwordVisibility = usePasswordVisibility({ initialType: type })

  return <Component {...rest} passwordVisibility={passwordVisibility} />
}

export default Container
