import React, { useEffect } from 'react'
import {
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Stack,
  UseCheckboxGroupReturn,
  CheckboxGroupProps,
} from '@chakra-ui/react'

import CheckboxCard from './CheckboxCard'

type Props<T> = {
  options: T[]
  valueExtractor: (item: T, index?: number) => string
  labelRenderer: (item: T, index?: number) => React.ReactElement
  defaultValue?: string[]
  title?: string
  checkboxGroup: UseCheckboxGroupReturn
} & CheckboxGroupProps

type Checkbox = <T>(props: Props<T>) => React.ReactElement

const Component: Checkbox = ({
  options,
  valueExtractor,
  labelRenderer,
  defaultValue,
  title,
  checkboxGroup,
  ...rest
}) => (
  <FormControl label={title}>
    {title && (
      <FormLabel fontWeight="bold" fontSize="lg" mb={4}>
        {title}
      </FormLabel>
    )}
    <CheckboxGroup defaultValue={defaultValue} {...rest}>
      <Stack>
        {options.map(item => {
          const key = labelRenderer(item)
          const value = valueExtractor(item)
          const checkbox = checkboxGroup.getCheckboxProps({ value })

          return (
            <CheckboxCard key={value} {...checkbox}>
              {key}
            </CheckboxCard>
          )
        })}
      </Stack>
    </CheckboxGroup>
  </FormControl>
)

const Container: Checkbox = ({ checkboxGroup, defaultValue, title, options, ...rest }) => {
  useEffect(() => {
    if (defaultValue) {
      checkboxGroup.setValue(defaultValue)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Component
      defaultValue={defaultValue}
      title={title}
      options={options}
      checkboxGroup={checkboxGroup}
      {...rest}
    />
  )
}

export default Container
